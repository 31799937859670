import $ from 'jquery';
import AWS from 'aws-sdk'
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { lockForm } from './utils';

/* Type Definitions for JSDoc comments */
/** @typedef {import('amazon-cognito-identity-js').CognitoIdToken} CognitoIdToken */
/** @typedef {import('amazon-cognito-identity-js').CognitoAccessToken} CognitoAccessToken */

AWS.config.region = config.region

var TargetUserPool = new CognitoUserPool({
    UserPoolId: config.userPoolId,
    ClientId: config.appClientId
})

var SignInStatus = {
    /** @type {CognitoUser} */
    currentCognitoUser: TargetUserPool.getCurrentUser(),
    /** @type {CognitoAccessToken} */
    lastIdentityToken: null,
    /** @type {CognitoIdToken} */
    lastAccessToken: null
}

function checkAttribute(name, onSuccess, onError) {
    SignInStatus.currentCognitoUser.getUserAttributes(function (err, data) {
        if (err) {
            if (onError) {
                onError();
            }

            return;
        }

        var attribute = data.find(attr => attr.Name === name);
        if (attribute && attribute.Value && attribute.Value !== '0') {
            if (onSuccess) {
                onSuccess();
            }
        }
        else {
            if (onError) {
                onError();
            }
        }
    });
}

function checkWatermarking(onSuccess, onError) {
    return checkAttribute('custom:deobfuscation', onSuccess, onError);
}

function checkTvKey(onSuccess, onError) {
    return checkAttribute('custom:keytype', onSuccess, onError);
}

function checkAccess(onError) {
    const loc = location.pathname
    const currentPage = loc.substr(loc.lastIndexOf('/') + 1)

    if (currentPage === 'deobfuscate.html') {
        checkWatermarking(null, onError)
    }
    else if (currentPage === 'encrypt.html') {
        checkTvKey(null, onError)
    } else if (currentPage === 'deploy_eme_package.html') {
        if (config.displayMode !== 'DEV') {
            onError();
        }
    }
}

var CognitoUtils = {
    signIn: function (username, password, callbacks) {
        var authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        })

        var cognitoUser = new CognitoUser({
            Username: username,
            Pool: TargetUserPool
        })

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                SignInStatus.lastAccessToken = result.getAccessToken()
                SignInStatus.lastIdentityToken = result.getIdToken()
                callbacks.onSuccess()
            },
            onFailure: function (err) {
                callbacks.onFailure(err)
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                $('#setPass-Modal').addClass('modalDialog-open');
                var that = this;
                var form = $('#setPass-form');
                form.unbind();
                form.submit(function (event) {
                    event.preventDefault();
                    $('#setPass-Modal').removeClass('modalDialog-open');
                    var password = $('#setPass-pass').val();
                    cognitoUser.completeNewPasswordChallenge(password, null, that);
                })
            }
        });
    },

    signOut: function (event) {
        lockForm($("#sign-out"), true);
        event.preventDefault();
        SignInStatus.currentCognitoUser.signOut();
        if (AWS.config.credentials) {
            AWS.config.credentials.clearCachedId();
        }
        SignInStatus.currentCognitoUser.clearCachedTokens();
        SignInStatus.lastIdentityToken = null;
        SignInStatus.lastAccessToken = null;
        SignInStatus.currentCognitoUser = null;
        $(location).attr('href', '../index.html');
    },

    checkSignInStatus: function () {
        var loc = location.pathname
        var currentPage = loc.substr(loc.lastIndexOf('/') + 1)
        // get the name of the current dir
        var path = loc.substring(0, loc.lastIndexOf('/'))
        var dir = path.substring(path.lastIndexOf('/') + 1)
        if (!SignInStatus.currentCognitoUser) {
            if (dir === 'operator') {
                $(location).attr('href', '../index.html')
            }
            return null
        }

        SignInStatus.currentCognitoUser.getSession(function (err, session) {
            if (err) {
                SignInStatus.currentCognitoUser.signOut()
                SignInStatus.currentCognitoUser.clearCachedTokens()
                SignInStatus.lastIdentityToken = null
                SignInStatus.lastAccessToken = null
                SignInStatus.currentCognitoUser = null

                return
            }

            SignInStatus.lastIdentityToken = session.getIdToken()
            SignInStatus.lastAccessToken = session.getAccessToken()
            session.getRefreshToken()

            if (SignInStatus.lastIdentityToken) {
                if (SignInStatus.lastAccessToken) {
                    var expiration = SignInStatus.lastAccessToken.getExpiration()
                    //var expiration = session.getRefreshToken().expiration
                    var now = parseInt(Date.now() / 1000) // millisecond to seconds
                    // if the token expires in less than a minute, log out
                    var delta = expiration - now
                    console.log("Expiration in " + delta + " seconds")
                    if (expiration - now > 60) {
                        if (currentPage === 'index.html') {
                            $(location).attr('href', 'operator/home.html')
                        }
                        else {
                            checkAccess(function () { $(location).attr('href', 'home.html') });
                            return;
                        }
                    } else {
                        alert('Token expired, logging out')
                        console.log('Token expired, logging out')
                        SignInStatus.currentCognitoUser.clearCachedTokens()
                        SignInStatus.lastIdentityToken = null
                        SignInStatus.lastAccessToken = null
                        SignInStatus.currentCognitoUser = null
                        if (dir === 'operator') {
                            $(location).attr('href', '../index.html')
                        }
                    }
                }
            } else {
                alert('No identity token available.')
                console.log('No identity token available.')
                if (dir === 'operator') {
                    $(location).attr('href', '../index.html')
                }
            }
        })
    },

    getOperatorSession: function (needAssumeRole, callback) {
        this.checkSignInStatus();

        AWS.config.region = config.region;
        var cognitoUser = TargetUserPool.getCurrentUser()

        cognitoUser.getSession(function (err, session) {
            if (err) {
                callback(err, 'Problem getting session');
                return;
            }
            var objLogin = {}
            objLogin[config.loginId] = session.getIdToken().getJwtToken()
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: config.identityPoolId,
                Logins: objLogin
            })

            var username = cognitoUser.getUsername()

            if (needAssumeRole) {
                var paramsSTS = {
                    DurationSeconds: 3600,
                    RoleArn: 'arn:aws:iam::' + config.accountId + ':role/' + config.role,
                    RoleSessionName: username
                }
                var sts = new AWS.STS()

                sts.assumeRole(paramsSTS, callback);
            } else {
                callback('', session)
            }
        });
    },

    initMenu: function () {
        if (config.displayMode == "DEV") {
            $('#depEmeKeys').show();
        }
        checkWatermarking(function () { $('#menuDeobfuscate').show(); }, function () { $('#menuDeobfuscate').hide(); });
        checkTvKey(function () { $('#menuEncrypt').show(); }, function () { $('#menuEncrypt').hide(); });
    },
}

export {
    CognitoUtils,
    SignInStatus,
    TargetUserPool
};