import $ from "jquery";
import { CognitoUtils } from './operator/cognito.js';

function PopUpForgotPwd() {
    var popup = $('#forgotPwdPopUp')
    popup.addClass('show')
    setTimeout(function () { popup.removeClass('show') }, 10000)
}

function changePasswordValidation() {
    if ($('#setPass-pass').val() == $('#setPass-confirm').val()) {
        $("#setPass-submit").removeAttr("disabled");
        $('#message').html('');
    } else {
        $("#setPass-submit").attr('disabled', 'disabled');
        $('#message').html('Passwords do not match').css('color', 'red');
    }
}

$(document).ready(function ($) {
    $('#setPass-pass, #setPass-confirm').on('keyup', changePasswordValidation);
    $('#setPass-pass, #setPass-confirm').change(changePasswordValidation);

    $("#PopUpForgotPwd").click(PopUpForgotPwd);

    $("#user-login").submit(function (event) {
        event.preventDefault();

        $("#user-login-fields").attr('disabled', 'disabled');

        CognitoUtils.signIn($('#edit-name').val(), $('#edit-pass').val(), {
            onSuccess: function () {
                $(location).attr('href', 'operator/home.html');
            },
            onFailure: function (err) {
                $("#user-login-fields").removeAttr('disabled');
                alert(err.message);
            }
        });
    });
});